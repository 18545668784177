<template>
<div class="cell large-10" style="text-align:center;padding:5px">



</div>
</template>


<script>
    export default {
        name: "hutchdutchgameresults",
        data() {
            return {
                betoptions: [],
            }
        },
        props: {
            GUID: Number,
            Title: String
        },

        components: {

        },
        computed: {

        },
        created() {

            this.getGames();


        },

        methods: {

            defineBetNames: function () {

                var self = this;

                //clear down the bet options before recreating
                self.betoptions = [];

                var arrayLength = this.selectedbets.length;
                for (var iloop = 0; iloop < arrayLength; iloop++) {

                    //need to iterate through all autocomplete values on the form

                    //Is the event tpo or OR
                    if (self.selectedbets[iloop].betname.includes(" v ")) {

                        var betnames = self.strip_html_tags(this.selectedbets[iloop].betname).split(" v ");

                        var bettype = self.selectedbets[iloop].bettype;

                        //Remove Teams - Over Under Goals / Double Chance / Odd or Even 
                        if (bettype != "Over or Under Goals" || bettype != "Odd or Even" || bettype != "Over or Under Corners") {
                            for (var i = 0; i < betnames.length; i++) {

                                self.betoptions.push(this.selectedbets[iloop].betno + '. ' + betnames[i]);
                            }

                        }

                        //Simple 2 outcomes - two team outcomes only do need to do anything else - Half Time / Score First/ MoneyLine
                        if (bettype == "Score First " || bettype == "Moneyline" || bettype == "Tournament Bet") {
                            continue;
                        }

                        // short name of draw (when multiple bets)
                        var draw = "Draw (" + self.betoptions[self.betoptions.length - 2] + " - " + self.betoptions[self.betoptions.length - 1] + ")"

                        //Get Bet Type and Extra Bet Info
                        var bt = self.selectedbets[iloop].bettype;

                        bettype = bt.substring(0, bt.indexOf("~"));
                        var extrabetinfo = bt.substring(bt.indexOf("~") + 1, bt.length);

                        //Simple 3 outcomes - Match Odds / Half Time
                        if (bettype == "Match Odds" || bettype == "Half Time") {
                            self.betoptions.push(draw);
                            continue;
                        }

                        //Enter specific values for specific bets
                        if (bettype == "Over or Under Goals" || bettype == "Over or Under Corners") {
                            self.betoptions.push("Over~" + extrabetinfo);
                            self.betoptions.push("Under~" + parseFloat(extrabetinfo) * -1);
                            continue;
                        }

                        if (bettype == "Odd or Even") {
                            self.betoptions.push("Odd");
                            self.betoptions.push("Even");
                            continue;
                        }

                        //Add Values to teams
                        if (bettype == "Asian Handicaps" || bettype == "Handicaps") {
                            self.betoptions[0] = self.betoptions[0] + '~' + extrabetinfo;
                            self.betoptions[1] = self.betoptions[1] + '~' + parseFloat(extrabetinfo) * -1;
                            continue;
                        }

                        //Double Chance - oddity
                        if (bettype == "Double Chance") {
                            self.betoptions[0] = self.betoptions[0] + ' or draw';
                            self.betoptions[1] = 'draw or ' + self.betoptions[1];
                            self.betoptions.push(self.betoptions[0] + ' or ' + self.betoptions[1]);
                            continue;
                        }

                    } else {
                        //need to go and get teams associated with that competition outcome (need competitionid and outrightbettypeid)

                        var URL = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/TeamsforOutright";

                        axios.get(URL, {

                            params: {
                                CompetitionID: this.selectedbets[iloop].entityid,
                                OutRightBetTypeID: this.selectedbets[iloop].bettypeid
                            }

                        }).then(response => {

                            if (response.data == '') {

                                this.selectedbets.pop();

                                this.genericConfirm(`This competition currently has no competitors (with odds). So will be removed`);

                            } else {

                                let json = JSON.parse(response.data);

                                //convert json data into an array
                                json.forEach(function (bo) {
                                    self.betoptions.push(bo.betname);
                                });
                            }
                        },
                            (error) => { console.log(error) }
                        );
                    }
                }
            },


            getGames: function () {

            

                var URL = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/SearchItems";

                return new Promise(resolve => {

                    axios.get(URL, {

                        params: {
                            desc: input,//parseInt(this.id), //can get multiple banner sizes by passing the banner number into the array
                            noitems: 50, //parseInt(this.noitems), // does nothing at the moment 18/01/2019
                            wildcard: true,
                            sportid: -1 
                        }

                    }).then(response => {

                    
                        let data = JSON.parse(response.data)[0];
                        if (Object.keys(data).length > 1) {
                            data = data.BO;
                        } else {
                            data = [];
                    }

                    return resolve(data);
                    },

                        (error) => { console.log(error) }
                    );
                });

            }
        }
    }
</script>


<style>


</style>